<template>
	<div class="p-grid">
		<div class="p-col-12">
			<h1>Notifications</h1>
		</div>

		<div class="p-col-12">
			<div class="p-fluid p-mb-3">
				<InfiniteProgress v-show="loading"></InfiniteProgress>
			</div>
			<div class="p-fluid p-ml-4 p-mr-4">
				<div v-if="notifications !== undefined" class="p-grid">
					<div v-for="n in notifications" :key="'notif_' + n.id" class="p-col-12">
						<a @click.prevent="clickTo(n)"
						   @mouseenter="hoverNotif(n)"
						   :href="n.url" class="card notification p-d-flex p-jc-center p-align-center">
							<div class="left-icon p-d-flex p-jc-center p-align-center">
								<div @click.stop="hoverNotif(n)"
									 v-bind:class="{unreaded: !n.readed}" class="read-indicator"></div>
								<span v-bind:class="{danger: n.type === 0, info: n.type ===  1, success: n.type ===  2, warn: n.type ===  3}"
										class="p-circle  p-d-flex p-jc-center p-align-center">
									<i :class="'pi ' + n.icon"></i>
								</span>
							</div>
							<div class="right-text p-d-flex">
								<div class="title notification-title">
									{{ n.title }}
								</div>
								<div class="notification-content">
									{{ n.value }}
								</div>
								<div class="notification-time">
									{{ tmsToDate(n.dateCreation) }}
								</div>
							</div>
						</a>
					</div>


					<div class="p-col-12 p-text-center">
						<a @click.prevent="loadMore" href="#" class="load-more text-muted">Voir plus ...</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ProfileService from "../service/ProfileService";
import calendarMixin from "../mixins/calendarMixin";
import InfiniteProgress from "../components/InfiniteProgress.vue";

export default {
	data(){
		return {
			data: undefined,
			page: 1,
			rows: 10,
			notifications: undefined,
			loading: true,
		}
	},
	created(){
		this.profileService = new ProfileService();
	},
	mounted(){
		this.getNotifications();
		window.addEventListener('app_notification', (e) => {
			console.log(e);
			this.page = 1;
			this.getNotifications();
		})
	},
	methods: {
		loadMore(){
			this.page++;
			this.getNotifications(true);
		},
		hoverNotif(notif) {

			if(notif === undefined || notif.readed){
				return;
			}

			notif.readed = true;

			this.profileService.updateNotification(notif['@id'],{
				readed: true,
			})
					.then(data => {
						if(data.error === true){
							throw new Error(data.message);
						}
						//reload unread notification from store
						this.profileService.getNotifications(1,1);
					})
					.catch(err => {
						console.warn('err',err);
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
		},
		clickTo(notif){
			this.$router.push({ path: notif.url.replace('/#/','/') })
		},
		getNotifications(append = false){
			this.loading = true;
			this.profileService.getNotifications(this.rows,this.page)
					.then(data => {
						if(data.error === true){
							throw new Error(data.message);
						}
						if(append){
							data.data.data.forEach(el => {
								this.notifications.push(el);
							});

						} else {
							this.notifications = data.data.data;
						}

					})
					.catch(err => {
						console.warn('err',err);
						this.$toast.add({severity:'error', summary: 'Une erreur est survenue', detail:err || 'erreur inconnue', life: 3000});
					})
					.finally(() => {
						this.loading = false;
					})
			;
		},
	},
	computed: {

	},
	mixins: [calendarMixin],
	components: {
		InfiniteProgress,
	}
}
</script>

<style scoped>

</style>
